import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    GetDeletedDevices,
    InitDeletedDevices,
    InitDeviceOptions,
    RecoveryDeletedDevice,
    SetDeletedDevicesFilter,
} from '../actions/deleted-devices.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { Pagination, Params } from '../../../app-shared-elements/_interfaces/params.interface';
import { initialFilterDeletedDevices } from '../../_data/deleted-device-data';
import { ParamsService } from '../../../app-shared-elements/_services/params.service';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { DeletedDevicesRowsInterface } from '../../_interfaces/deleted-devices-rows.interface';
import { DeletedDevicesService } from '../../_services/deleted-devices.service';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';

export interface DeletedDevicesStateModel {
    deletedDevices: Device[];
    deletedDevicesRows: DeletedDevicesRowsInterface[];
    params: Params;
    deviceOptions: SelectOptionInterface<string, string, Device>[];
}

const DELETED_DEVICES_TOKEN = new StateToken<DeletedDevicesStateModel>('deletedDevices');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

export const initialParams: Params = {
    filter: initialFilterDeletedDevices,
    sorted: null,
    pagination: initialPagination,
};

@State<DeletedDevicesStateModel>({
    name: DELETED_DEVICES_TOKEN,
    defaults: {
        deletedDevices: [],
        deletedDevicesRows: [],
        params: initialParams,
        deviceOptions: [],
    },
})
@Injectable()
export class DeletedDevicesState {
    constructor(
        private http: HttpClient,
        private paramsService: ParamsService,
        private notificationsService: NotificationsService,
        private store: Store,
        private deletedDevicesService: DeletedDevicesService,
    ) {}

    @Selector()
    static getDeletedDevices(state: DeletedDevicesStateModel): Device[] {
        return state.deletedDevices;
    }

    @Selector()
    static getDeletedDevicesRows(state: DeletedDevicesStateModel): DeletedDevicesRowsInterface[] {
        return state.deletedDevicesRows;
    }

    @Selector()
    static getParams(state: DeletedDevicesStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getDeviceOptions(state: DeletedDevicesStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.deviceOptions;
    }

    @Action(GetDeletedDevices)
    async getDeletedDevices(ctx: StateContext<DeletedDevicesStateModel>): Promise<void> {
        const state = ctx.getState();
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: null,
        };

        const headers = new HttpHeaders({
            params: encodeURIComponent(JSON.stringify(params)),
        });

        const result: ApiResponse = (await this.http
            .get('/api/devices/deleted', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                deletedDevices: result.data,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.length,
                    },
                },
            });

            await ctx.dispatch(new InitDeletedDevices()).toPromise();
        }
    }

    @Action(InitDeletedDevices)
    initDeletedDevices(ctx: StateContext<DeletedDevicesStateModel>): void {
        const state = ctx.getState();
        const devices = state.deletedDevices;
        const user = this.store.selectSnapshot(UserState.getUser);
        const deletedDevicesRows: DeletedDevicesRowsInterface[] = [];

        devices
            .filter((d) => d.type === DeviceTypeEnum.registrator)
            .sort((a, b) => new Date(a.deleted).getTime() - new Date(b.deleted).getTime())
            .forEach((registrator) => {
                deletedDevicesRows.push({
                    ...registrator,
                    name: registrator.name ?? registrator.defaultName,
                    iconType: registrator.isActive ? 'devices.on' : 'devices.off',
                    preIcons: this.deletedDevicesService.getPreIcons(registrator, user),
                    styles: {
                        iconType: this.deletedDevicesService.getConnectStyle(registrator),
                    },
                });

                devices
                    .filter((d) => d.registratorId === registrator.id)
                    .sort((a, b) => new Date(a.deleted).getTime() - new Date(b.deleted).getTime())
                    .forEach((device) => {
                        deletedDevicesRows.push({
                            ...device,
                            name: device.name ?? device.defaultName,
                            preIcons: this.deletedDevicesService.getPreIcons(device, user, registrator),
                            styles: {
                                iconType: this.deletedDevicesService.getConnectStyle(device),
                            },
                        });
                    });
            });

        ctx.setState({
            ...state,
            deletedDevicesRows,
        });
    }

    @Action(RecoveryDeletedDevice)
    async recoveryDeletedDevice(ctx: StateContext<DeletedDevicesStateModel>, payload: RecoveryDeletedDevice): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/devices/recovery', { id: payload.id })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new GetDeletedDevices());
        }
    }

    @Action(SetDeletedDevicesFilter)
    async setDeletedDevicesFilter(ctx: StateContext<DeletedDevicesStateModel>, payload: SetDeletedDevicesFilter): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: payload.params,
        });

        ctx.dispatch(new GetDeletedDevices());
    }

    @Action(InitDeviceOptions)
    async initDeviceOptions(ctx: StateContext<DeletedDevicesStateModel>): Promise<void> {
        const state = ctx.getState();
        const devices = this.store.selectSnapshot(DeletedDevicesState.getDeletedDevices);
        const deviceOptions: SelectOptionInterface<string, string, Device>[] = [];

        devices
            .filter((d) => d.type === DeviceTypeEnum.registrator)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .forEach((r) => {
                deviceOptions.push({
                    key: r.id,
                    value: r.name ?? r.defaultName,
                    property: r,
                });

                devices
                    .filter((d) => d.registratorId === r.id)
                    .sort((a, b) => (a.type > b.type ? 1 : -1))
                    .forEach((d) => {
                        deviceOptions.push({
                            key: d.id,
                            value: d.name ?? d.defaultName,
                            property: d,
                        });
                    });
            });

        ctx.setState({
            ...state,
            deviceOptions,
        });
    }
}
