import {ParamsFilterForClient, ParamsFilterTypeEnum} from '../../app-shared-elements/_interfaces/params.interface';

export const initialFilterDeletedDevices: ParamsFilterForClient[] = [
    {
        property: 'registratorId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    }
];
